exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-list-js": () => import("./../../../src/pages/price-list.js" /* webpackChunkName: "component---src-pages-price-list-js" */),
  "component---src-pages-treatments-anti-wrinkle-js": () => import("./../../../src/pages/treatments/anti-wrinkle.js" /* webpackChunkName: "component---src-pages-treatments-anti-wrinkle-js" */),
  "component---src-pages-treatments-dermal-fillers-js": () => import("./../../../src/pages/treatments/dermal-fillers.js" /* webpackChunkName: "component---src-pages-treatments-dermal-fillers-js" */),
  "component---src-pages-treatments-facials-js": () => import("./../../../src/pages/treatments/facials.js" /* webpackChunkName: "component---src-pages-treatments-facials-js" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */)
}


import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faChevronRight, faMapPin } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import * as Constants from '../constants'

import * as styles from "./contact.module.css"

import TestimonialInclude from "../components/includes/testimonial-include"
import DotsElement from "../components/theme/dots";

export default class ContactPage extends React.Component {


  constructor(props) {
    super(props)

    this.state = { submitted: false }
    this.submit = this.submit.bind(this);
  }

  submitSuccess(response) {
    console.log(response)
    if (response.status === 200) {
      this.setState({ submitted: true });
    } else {
      alert("Failed to submit contact form: " + response.status)
    }
  }

  submitFailure() {
    alert("Failed to submit contact form")
  }

  submit(event) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Object.fromEntries(new FormData(event.target)))
    };
    fetch('https://formspree.io/f/xyylrpqe', requestOptions)
      .then((response) => this.submitSuccess(response))
      .catch((error) => this.submitFailure(error))

    event.preventDefault();
  }

  render() {
    return (
      <Layout id="contact-page">
        <Helmet>
          <title>Contact ACA Rejuvenate Clinic</title>
        </Helmet>
        <div class="bg-green-2 pt-md-5 pb-3 pb-md-5">
          <div class="container-md">
            <div class="row">
              <div class="col-lg-4 header-photo">
              </div>
              <div class="col-lg-8 about-us-area">
                <div class="d-lg-none mb-4 mt-5"><DotsElement height="1" color="green" /></div>
                <div class="mb-4 mt-5 mt-lg-1 d-none d-lg-block"><DotsElement height="2" width="2" color="green" /></div>

                <div class="medi-title text-white mb-1 d-none d-md-block">CONTACT US</div>
                <div class="medi-strap mb-2 d-none d-md-block">ACA Rejuvenate Clinic</div>

                <div class="medi-strap mb-2 d-block d-md-none">Contact Us</div>

                <p>
                  Here at ACA Rejuvenate Clinic we know a great experience begins with good communication.
                  Our aim is to be contactable when you need us to discuss treatments, answer your questions and provide follow-up advise.
                </p>
                <p>
                  We've made contacting us as easy as possible in whichever way works best for you.
                </p>
                <div class="social">

                  <a className={styles.socialLink} target="_blank" rel="noopener noreferrer" href={Constants.facebook}>
                    <FontAwesomeIcon icon={faFacebookSquare} size="1x" />
                  </a>
                  <a className={styles.socialLink} target="_blank" rel="noopener noreferrer" href={Constants.instagram}>
                    <FontAwesomeIcon icon={faInstagram} size="1x" />
                  </a>
                  <a className={styles.socialLink} target="_blank" rel="noopener noreferrer" href={"tel:" + Constants.telephone}>
                    <FontAwesomeIcon icon={faPhone} size="1x" />
                  </a>
                  <a className={styles.socialLink} target="_blank" rel="noopener noreferrer" href={Constants.whatsapp_link}>
                    <FontAwesomeIcon icon={faWhatsapp} size="1x" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="container-fluid container-md pt-3 pt-xl-5">
            <div class="row medi-row pt-0 pt-sm-5">

              <div class="col-lg-4">
                <div class="medi-title">Contact the Clinic</div>
                <div class="medi-content">
                  <p>
                    Many of the treatments offered at ACA Rejuvenate Clinic are legally required to be administered by a qualified, registered nurse.
                    With a broad range of experience across the healthcare sector the ACA Rejuvenate Clinic team are able to provide guidance on types of aesthetic treatments and answer questions relating to procedures and aftercare.
                  </p>
                  <p>
                    We understand that aesthetic treatments will be new to a lot of our clients and appreciate that speaking to an expert can help guide clients through this process.
                    Please reach out to us if you're considering an aesthetic procedure but you are feeling a little unsure.
                  </p>
                </div>
              </div>

              <div className={`col-lg-8 ${this.state.submitted ? "d-none" : ""}`}>
                <div class="medi-title">&nbsp;</div>
                <div class="medi-content">
                  <form onSubmit={this.submit}>
                    <div class="mb-3">
                      <label htmlFor="name" class="form-label">Name</label>
                      <input class="form-control" id="name" name="name" />
                    </div>
                    <div class="mb-3">
                      <label htmlFor="email" class="form-label">Email address</label>
                      <input type="email" class="form-control" id="email" placeholder="name@example.com" name="email" />
                    </div>
                    <div class="mb-3">
                      <label htmlFor="telephone" class="form-label">Phone Number</label>
                      <input type="tel" class="form-control" id="telephone" name="telephone" />
                    </div>
                    <div class="mb-3">
                      <label htmlFor="message" class="form-label">Message</label>
                      <textarea class="form-control" id="message" rows="5" name="message"></textarea>
                    </div>
                    <div className={styles.formButtons}>
                      <button class="btn btn-green-inverse" type="submit">
                        Submit &nbsp;
                        <FontAwesomeIcon icon={faChevronRight} size="1x" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className={`col-lg-8 ${this.state.submitted ? "" : "d-none"}`}>
                <div class="medi-strap">Thank you</div>
                <div class="medi-content">
                  Thank you for submitting your contact form. A member of the team will be in contact soon.
                </div>
              </div>

            </div>
          </div>
        </div>



        <div class="bg-charcoal">
          <div class="container-fluid container-md">
            <div class="row medi-row pt-5">
              <div class="col-lg-6">
                <div class="mb-4 mt-5 mt-lg-1 d-none d-lg-block"><DotsElement height="2" width="2" color="light-green" /></div>
                <div class="medi-content">
                  <div className={[styles.locationBlock, "mb-5", "pb-4"].join(" ")}>
                    <div class="medi-title mb-4">Our Location</div>
                    <FontAwesomeIcon icon={faMapPin} size="1x" fixedWidth="true" className="medi-icon d-inline-block mx-2" /> &nbsp;
                    10a Sandway Drive, Thorpe Willoughby <br />
                    <FontAwesomeIcon icon={faMapPin} size="1x" fixedWidth="true" className="medi-icon d-inline-block mx-2 invisible" /> &nbsp;
                    Selby, YO8 9NF
                  </div>
                  <div className={[styles.locationBlock, "pb-4"].join(" ")}>
                    <div class="medi-title mb-4">Contact</div>
                    <FontAwesomeIcon icon={faEnvelope} size="1x" fixedWidth="true" className="medi-icon d-inline-block mx-2" /> &nbsp;
                    <a href={"mailto:" + Constants.email}>{Constants.email}</a>
                    <br />
                    <FontAwesomeIcon icon={faPhone} size="1x" fixedWidth="true" className="medi-icon d-inline-block mx-2" /> &nbsp;
                    <a target="_blank" rel="noopener noreferrer" href={"tel:" + Constants.telephone}>{Constants.telephone}</a>
                    <br />
                    <FontAwesomeIcon icon={faWhatsapp} size="1x" fixedWidth="true" className="medi-icon d-inline-block mx-2" /> &nbsp;
                    <a target="_blank" rel="noopener noreferrer" href={Constants.whatsapp_link}>WhatsApp Message</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 p-3">
                <iframe className={styles.mapFrame} loading="lazy" allowfullscreen src={"https://www.google.com/maps/embed/v1/place?q=" + Constants.map_address + "&key=AIzaSyBRdMpznwxCTt94u0Hm8j0Ut2PuGU5514c"} title="Google Maps"></iframe>
              </div>
            </div>
          </div>
        </div>

        <TestimonialInclude />
      </Layout>
    )
  }
}
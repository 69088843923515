import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBriefcaseMedical, faChevronRight, faClipboardList, faHourglassHalf, faMedkit, faSyringe, faUserNurse } from "@fortawesome/free-solid-svg-icons"
import { faCalendarAlt, faClipboard, faClock } from '@fortawesome/free-regular-svg-icons'
import DotsElement from "../../components/theme/dots"

import * as styles from "./dermal-fillers.module.css"
import * as constants from '../../constants'

export default class DermalFillerTreatment extends React.Component {
    render() {
        return (
            <Layout id="dermal-fillers-page">
                <Helmet>
                    <title>Dermal Fillers - ACA Rejuvenate Clinic</title>
                </Helmet>

                <div class="bg-green-2 pt-md-5 pb-3 pb-md-5">
                    <div class="container-md">
                        <div class="row">
                            <div class="header-photo col-lg-4">
                            </div>
                            <div class="col-lg-8 about-us-area pt-4 pb-2">
                                <div class="medi-title text-white mb-1">TREATMENTS</div>
                                <div class="medi-strap mb-2">Dermal Fillers</div>
                                <p>
                                    Dermal fillers provide a smooth, long-lasting correction of moderate to severe facial wrinkles and folds in both men and women.
                                </p>
                                <p>
                                    They instantly soften tell-tale ageing and enhance your natural beauty by restoring volume, improving elasticity and smooth lines and imperfections within your facial complexion.
                                </p>
                                <p>
                                    Dermal fillers that contain the hyaluronic acid (naturally produced by your body, with production slowing down as you age) are a popular choice to help these changes because they can help improve and slow down the signs of ageing and rehydrate the skin tissue. Treatment is quick, convenient, minimally invasive and results can be seen straight away without any recovery period.
                                </p>
                                <a href={constants.booking_url} class="btn btn-green-inverse booking-button">Book Now &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="container">
                        <div class="row medi-row pt-3 pt-sm-5 pb-2">

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Treatment  key facts</div>
                                <div class="medi-content mb-3 mb-lg-5">What to expect from dermal filler treatment</div>
                            </div>
                            <div class="col-lg-4 col-md-6">

                                <span className="treatment-icon"><FontAwesomeIcon icon={faSyringe} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Treatment Type</span><br />
                                <span className="treatment-value treatment-value-nl">Injection</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faBriefcaseMedical} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Anesthesia</span><br />
                                <span className="treatment-value treatment-value-nl">
                                    Can cause possible mild discomfort, medical numbing cream is advised 
                                    (applied 30 minutes before procedure)
                                </span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faMedkit} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Side Effects</span><br />
                                <span className="treatment-value treatment-value-nl">Possible swelling, minor bruising, small lumps under the skin – should resolve within a few days</span>
                            </div>

                            <div class="col-lg-4 col-md-6">

                                <span className="treatment-icon"><FontAwesomeIcon icon={faClipboard} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Treatment Areas</span><br />
                                <span className="treatment-value treatment-value-nl"> Nose to mouth lines (nasolabial folds), smile lines, mouth to chin lines (Marionette lines)</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faClock} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Duration</span><br />
                                <span className="treatment-value treatment-value-nl">
                                    45-90 minutes
                                </span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faClipboardList} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Results</span><br />
                                <span className="treatment-value treatment-value-nl">
                                    Drastically reduced appearance of fine-lines and wrinkles
                                </span>
                                <br />
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <span className="treatment-icon"><FontAwesomeIcon icon={faCalendarAlt} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Frequency</span><br />
                                <span className="treatment-value treatment-value-nl">3 – 24 months (dependent on product choice)</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faHourglassHalf} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Recovery Period</span><br />
                                <span className="treatment-value treatment-value-nl">None</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faUserNurse} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Follow-up</span><br />
                                <span className="treatment-value treatment-value-nl">For 24 hours – avoid touching face, no high exertion activity</span>
                            </div>
                            <div className="col-lg-12">
                                <DotsElement height="1" color="green" />
                            </div>

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Important Information</div>
                                <div class="medi-content mb-3 mb-lg-5">Things you need to know before booking treatment</div>
                            </div>
                            <div class="col-lg-12">
                                <div class="medi-content">
                                    <p>
                                        Although we assess each client on a case by case basis it is extremely important to let us know if you suffer from any of the following before your treatment:
                                        <ul>
                                            <li>Auto-immune disease</li>
                                            <li>Allergies</li>
                                            <li>Neurological condition</li>
                                            <li>Any previous medical problems involving your face</li>
                                            <li>Previous sensitivities</li>
                                            <li>Cold sores</li>
                                        </ul>
                                        This doesn't mean that you will be unable to undergo your chosen procedure, it just means your Nurse will need to take additional care in deciding if this is the right treatment for you, as your safety is our top priority.
                                    </p>
                                    <p>
                                        Please also be aware we will be unable to perform your procedure 2 weeks before or after a Covid-19 vaccination - this is in your best interest to ensure you get the results you desire.

                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <DotsElement height="1" color="green" />
                            </div>

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Frequently asked questions</div>
                                <div class="medi-content mb-3 mb-lg-5">All your questions answered by our medical staff</div>
                            </div>
                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">
                                        What are dermal fillers?
                                    </p>
                                    <p>
                                        (The Science bit) Dermal fillers consist of Hyaluronic Acid which is naturally produced by your body, lessening with age and external factors. As the Hyaluronic Acid particles in Dermal Fillers are much bigger particles than those produced naturally by your body they last much longer and take more time for your body to break down.
                                    </p>
                                    <p>
                                        Dermal fillers can be used to provide many different outcomes, with the end result being tailored for exactly what you want, these include:
                                        <ul>
                                            <li>Improving the appearance of fine or deep lines and folds</li>
                                            <li>Restoring facial volume</li>
                                            <li>Facial lifting effects</li>
                                        </ul>
                                        All working together to provide a more youthful, natural finish and the best version of you! Using specialist techniques, performed solely by highly experienced qualified nurses, these injectable procedures can reduce the appearance of both fine lines and wrinkles, creating a more youthful, vibrant and natural look.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">
                                        What brand of fillers are used?
                                    </p>
                                    <p>
                                        Here at ACA Rejuvenate Clinic we only use the finest tried and tested products and for this reason we currently only use Juvéderm&reg; in order to be guaranteed fantastic results every time.
                                        <br /><br />
                                    </p>
                                    <p class="medi-title">The most common pre-conception</p>
                                    <p>
                                        If you were to ask someone their biggest fear when it comes to anti-wrinkle and dermal filler procedures it would be that other people would be able to tell and they'd look like they'd had "work done".
                                    </p>
                                    <p>
                                        Here at ACA Rejuvenate we want to give you confidence in the fact that we would never undertake a treatment with the end result of providing "falseness", our aim will always be to enhance and retain your youthful natural beauty, ensuring you get the results that are right for you.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <DotsElement height="1" color="green" />
                            </div>


                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">About Juvéderm&reg;</div>
                                <div class="medi-content mb-3 mb-lg-5"></div>
                            </div>

                            <div class="col-lg-12">
                                <div class="medi-content">
                                    <p>
                                        Juvéderm&reg; is the world’s leading brand of facial filler and here at ACA Rejuvenate Clinic, we use it because we have the ultimate confidence in the products renowned for their safety and overall performance, making it the best product for you. They consist of Hyaluronic Acid which can help shape and contour your specific facial features, whilst keeping your skin optimally hydrated. Juvéderm&reg; adds volume to specific areas smoothing out lines, wrinkles and creases, creating a brighter, fresher look.
                                    </p>
                                    <p>
                                        Juvéderm&reg; unlike other lower quality dermal fillers already contains Lidocaine (a local anaesthetic) which helps reduce discomfort during the procedure and some residual pain relief post procedure. Results are seen shortly after treatment, giving you a glimpse of the new enhanced you straight away.
                                    </p>
                                    <p>
                                        Here at ACA Rejuvenate Clinic we use 2 core types of Juvéderm&reg;: Juvéderm&reg; Ultra 3 &amp; Juvéderm&reg; VOLIFT
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">

                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">
                                        Juvéderm&reg; Ultra 3
                                    </p>
                                    <p>
                                        A staple in the Juvéderm&reg; range, which offers versatility in contouring and volumising facial lines and folds, filling deeper lines.
                                    </p>
                                    <p>
                                        It has been specially formulated to:
                                        <ul>
                                            <li>Provide instant results</li>
                                            <li>Contour the area around your mouth</li>
                                            <li>Restore and create lost volume</li>
                                            <li>Enhance the natural features of the lower face</li>
                                            <li>Effects lasting 6-9 months</li>
                                        </ul>

                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">
                                        Juvéderm&reg; VOLIFT
                                    </p>
                                    <p>
                                        The latest facial filler created by Juvéderm&reg;, which helps contour, restore and create volume around the mouth area for the extended period of 12-15 months.
                                    </p>
                                    <p>
                                        It has been specially formulated to:
                                        <ul>
                                            <li>Provide instant results</li>
                                            <li>Contour the area around your mouth</li>
                                            <li>Restore and create lost volume</li>
                                            <li>Enhance the natural features of the lower face</li>
                                            <li>Longer lasting effects</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="bg-green-2 pt-5">
                    <div class="container mt-2">

                        <div class="row">

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Other popular treatments</div>
                                <div class="medi-content mb-3 mb-lg-5">Other treatments offered by ACA Rejuvenate Clinic</div>
                            </div>

                            <div class="col-lg-6 mb-3 mb-lg-5">
                                <div class={["card mb-2 h-100"].join(" ")}>
                                    <div class="row g-0 h-100">
                                        <div className={["col-md-6 mb-2 mb-md-0", styles.antiwrinkleCardPhoto].join(" ")}>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="card-body d-flex flex-column h-100">
                                                <h5 class="card-title">Anti-wrinkle Treatment</h5>
                                                <p class="card-text">Using specialist techniques, performed solely by highly experienced qualified nurses, these injectable procedures can reduce the appearance of both fine lines and wrinkles, creating a more youthful, vibrant and natural look.</p>
                                                <a href="/treatments/anti-wrinkle" class="btn btn-green-inverse mt-auto">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-3 mb-lg-5">
                                <div class={["card mb-2 h-100"].join(" ")}>
                                    <div class="row g-0 h-100">
                                        <div className={["col-md-6 mb-2 mb-md-0", styles.facialCardPhoto].join(" ")}>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="card-body d-flex flex-column h-100">
                                                <h5 class="card-title">Facial</h5>
                                                <p class="card-text">An experience performed by formally trained facial therapists, providing a bespoke treatment tailored to your specific skin type and desired outcome. Facials have the ability to combine different high-quality products and skilled techniques to rejuvenate skin.</p>
                                                <a href="/treatments/facials" class="btn btn-green-inverse mt-auto">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Layout>
        )
    }
}
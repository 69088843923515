import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBriefcaseMedical, faChevronRight, faHourglassHalf, faMedkit, faSwimmer, faSyringe } from "@fortawesome/free-solid-svg-icons"
import { faCalendarAlt, faClipboard, faClock } from '@fortawesome/free-regular-svg-icons'
import DotsElement from "../../components/theme/dots"

import * as styles from "./anti-wrinkle.module.css"
import * as constants from '../../constants'

export default class AntiWrinkleTreatment extends React.Component {
    render() {
        return (
            <Layout id="anti-wrinkle-page">
                <Helmet>
                    <title>Antiwrinkle Treatment - ACA Rejuvenate Clinic</title>
                </Helmet>

                <div class="bg-green-2 pt-md-5 pb-3 pb-md-5">
                    <div class="container-md">
                        <div class="row">
                            <div class="header-photo col-lg-4">
                            </div>
                            <div class="col-lg-8 about-us-area pt-4 pb-2">
                                <div class="medi-title text-white mb-1">TREATMENTS</div>
                                <div class="medi-strap mb-2">Antiwrinkle Treatment</div>
                                <p>
                                    Botulinum toxin Type A is a non-surgical anti-wrinkle treatment equally ideal for both men and
                                    women. Regardless of age or appearance of current wrinkles it can be beneficial for both young and older.
                                </p>
                                <p>
                                    It works not only to relax the muscles in your chosen areas, thus reducing the appearance of both
                                    fine lines and wrinkles, but it also trains the muscles in your chosen area not to contract.
                                </p>
                                <p>
                                    The reduction in these autonomic facial movements long term, which you do sub-consciously,
                                    reduces the movements which can cause fine lines and wrinkles.
                                </p>
                                <a href={constants.booking_url} class="btn btn-green-inverse booking-button">Book Now &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="container">
                        <div class="row medi-row pt-3 pt-sm-5">

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Treatment  key facts</div>
                                <div class="medi-content mb-3 mb-lg-5">What to expect from antiwrinkle treatment</div>
                            </div>
                            <div class="col-lg-4 col-md-6">

                                <span className="treatment-icon"><FontAwesomeIcon icon={faSyringe} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Treatment Type</span><br />
                                <span className="treatment-value treatment-value-nl">Injection</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faBriefcaseMedical} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Anesthesia</span><br />
                                <span className="treatment-value treatment-value-nl">Not required, low pain and minimally invasive</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faMedkit} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Side Effects</span><br />
                                <span className="treatment-value treatment-value-nl">Minor bruising &amp; headache</span>
                            </div>

                            <div class="col-lg-4 col-md-6">

                                <span className="treatment-icon"><FontAwesomeIcon icon={faClipboard} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Treatment Areas</span><br />
                                <span className="treatment-value treatment-value-nl">Frown lines, horizontal forehead lines, crow’s feet</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faClock} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Duration</span><br />
                                <span className="treatment-value treatment-value-nl">
                                    15 mins pre-procedure consultation <br />
                                    30 mins antiwrinkle treatment <br />
                                    15-30 minutes follow-up <i>*</i>
                                    <br />
                                    <i class="mt-3 d-block">* non-essential but strongly recommended</i>
                                </span>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <span className="treatment-icon"><FontAwesomeIcon icon={faCalendarAlt} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Frequency</span><br />
                                <span className="treatment-value treatment-value-nl">12 weeks</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faHourglassHalf} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Recovery Period</span><br />
                                <span className="treatment-value treatment-value-nl">None</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faSwimmer} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Follow-up Advice</span><br />
                                <span className="treatment-value treatment-value-nl">24 hours without high exertion activity (e.g. gym)</span>
                            </div>



                            <div className="col-lg-12">
                                <DotsElement height="1" color="green" />
                            </div>

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Important Information</div>
                                <div class="medi-content mb-3 mb-lg-5">Things you need to know before booking treatment</div>
                            </div>
                            <div class="col-lg-12">
                                <div class="medi-content">
                                    <p>
                                        Although we assess each client on a case by case basis it is extremely important to let us know if you suffer from any of the following before your treatment:
                                        <ul>
                                            <li>Auto-immune disease</li>
                                            <li>Allergies</li>
                                            <li>Neurological condition</li>
                                            <li>Any previous medical problems involving your face</li>
                                            <li>Previous sensitivities</li>
                                            <li>Cold sores</li>
                                        </ul>
                                        This doesn't mean that you will be unable to undergo your chosen procedure, it just means your Nurse will need to take additional care in deciding if this is the right treatment for you, as your safety is our top priority.
                                    </p>
                                    <p>
                                        Please also be aware we will be unable to perform your procedure 2 weeks before or after a Covid-19 vaccination - this is in your best interest to ensure you get the results you desire.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="medi-content">
                                    <p class="medi-title">The most common pre-conception</p>
                                    <p>
                                        If you were to ask someone their biggest fear when it comes to anti-wrinkle and dermal filler procedures it would be that other people would be able to tell and they'd look like they'd had "work done".
                                    </p>
                                    <p>
                                        Here at ACA Rejuvenate we want to give you confidence in the fact that we would never undertake a treatment with the end result of providing "falseness", our aim will always be to enhance and retain your youthful natural beauty, ensuring you get the results that are right for you.
                                    </p>
                                </div>
                            </div>



                            <div className="col-lg-12">
                                <DotsElement height="1" color="green" />
                            </div>

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Frequently asked questions</div>
                                <div class="medi-content mb-3 mb-lg-5">All your questions answered by our medical staff</div>
                            </div>
                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">
                                        What is "antiwrinkle treatment"?
                                    </p>
                                    <p>
                                        Antiwrinkle treatment involves the injection of a protein called "Botulinum type A" which is often referred to as "botox".
                                        This procedure relaxes facial muscles helping to reduce the appearance of lines and wrinkles.
                                    </p>
                                    <p class="medi-title">
                                        What is Botulinum type A?
                                    </p>
                                    <p>
                                        A purified protein proven to have long-term effects on the relaxation of muscles helping to reduce wrinkles and lines.
                                        This is a prescription only procedure, which here at ACA Rejuvenate Clinic will only ever be provided by a Registered Nurse.
                                        Simple, quick, effective, non-invasive.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">What brands of botulinum are used?</p>
                                    <p>
                                        ACA Rejuvenate will only ever use one of three medically approved brands of botulinum toxin which are: Bocouture&trade;, Azzalure&trade; and BOTOX&trade;
                                        The choice of which toxin is in your hands with our expertise support.
                                    </p>
                                    <p class="medi-title">Which facial areas benefit most?</p>
                                    <p>
                                        Most popularly used to target problem areas such as deep creases between the eyebrows (frown lines), crow’s feet and horizontal forehead lines.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div class="bg-green-2 pt-5">
                    <div class="container mt-2">

                        <div class="row">

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Other popular treatments</div>
                                <div class="medi-content mb-3 mb-lg-5">Other treatments offered by ACA Rejuvenate Clinic</div>
                            </div>


                            <div class="col-lg-6 mb-3 mb-lg-5">
                                <div class={["card mb-2 h-100"].join(" ")}>
                                    <div class="row g-0 h-100">
                                        <div className={["col-md-6 mb-2 mb-md-0", styles.dermalFillersCardPhoto].join(" ")}>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="card-body d-flex flex-column h-100">
                                                <h5 class="card-title">Dermal Fillers</h5>
                                                <p class="card-text">A minimally invasive procedure, fulfilled by professionals with a high level of expertise. A soft tissue booster to provide facial volume and enhance facial features, restoring a smoother appearance and minimize the presence of fine lines and wrinkles.</p>
                                                <a href="/treatments/dermal-fillers" class="btn btn-green-inverse mt-auto">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-3 mb-lg-5">
                                <div class={["card mb-2 h-100"].join(" ")}>
                                    <div class="row g-0 h-100">
                                        <div className={["col-md-6 mb-2 mb-md-0", styles.facialCardPhoto].join(" ")}>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="card-body d-flex flex-column h-100">
                                                <h5 class="card-title">Facial</h5>
                                                <p class="card-text">An experience performed by formally trained facial therapists, providing a bespoke treatment tailored to your specific skin type and desired outcome. Facials have the ability to combine different high-quality products and skilled techniques to rejuvenate skin.</p>
                                                <a href="/treatments/facials" class="btn btn-green-inverse mt-auto">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </Layout>
        )
    }
}
import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

import * as styles from "./treatments.module.css"

import * as constants from '../constants'

import WrinkleCardHeader from "../../static/anti-wrinkle.jpg"
import DermalFillerCardHeader from "../../static/dermal-fillers.jpg"
import FacialCardHeader from "../../static/facials.jpg"


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faSyringe } from "@fortawesome/free-solid-svg-icons"
import { faClipboard, faClock } from '@fortawesome/free-regular-svg-icons'
import DotsElement from "../components/theme/dots"

export default class Treatments extends React.Component {
    render() {
        return (
            <Layout id="treatments-page">
                <Helmet>
                    <title>Treatments Available at ACA Rejuvenate Clinic</title>
                </Helmet>

                <div class="bg-green-2 pt-md-5 pb-3 pb-md-5">
                    <div class="container-md">
                        <div class="row">
                            <div class="header-photo col-lg-4">
                            </div>
                            <div class="col-lg-8 about-us-area">
                                <div class="d-lg-none mb-4 mt-5"><DotsElement height="1" color="green" /></div>
                                <div class="mb-4 mt-5 mt-lg-1 d-none d-lg-block"><DotsElement height="2" width="2" color="green" /></div>

                                <div class="medi-title text-white mb-1 d-none d-md-block">TREATMENTS</div>
                                <div class="medi-strap mb-2 d-none d-md-block">ACA Rejuvenate Clinic</div>

                                <div class="medi-strap mb-2 d-block d-md-none">Aesthetic Treatments</div>

                                All our procedures are carried out by highly trained staff. This includes Nursing Medical Council Qualified nurses to perform all injectable procedures and Dermal Therapists trained and committed to providing you with the highest level of skincare.

                                <div class="social">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pb-5">
                    <div class="container-fluid container-xxl">

                        <div class="row">


                            {/* START OF CARD */}

                            <div class="col-md-6 col-xl-12">
                                <div class={["card", styles.treatmentCard].join(" ")}>
                                    <div class="row g-0">
                                        <div class="col-xl-6">
                                            <img src={WrinkleCardHeader} class="card-img" alt="Antiwrinkle Treatment" />
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="card-body pt-lg-0">
                                                <div class="d-flex flex-row justify-content-between card-title ">
                                                    <h5 id="anti-wrinkle" class="medi-title">Antiwrinkle Treatment</h5>
                                                    <p className={styles.price}>From &pound;135</p>
                                                </div>
                                                <div class="card-text">
                                                    <p>
                                                        Botulinum toxin Type A is a non-surgical anti-wrinkle treatment ideal equally for both men and
                                                        women. Regardless of age or appearance of current wrinkles it can be beneficial for both young and older.
                                                    </p>
                                                    <p>
                                                        It works to not only to relax the muscles in your chosen areas, thus reducing the appearance of both
                                                        fine lines and wrinkles, it also trains the muscles in your chosen area not to contract.
                                                    </p>
                                                    <p>
                                                        The reduction in these autonomic facial movements, long term, which you use sub-consciously,
                                                        reduces their use which cause fine lines and wrinkles
                                                    </p>
                                                    <p>
                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faSyringe} size="1x" /></span>
                                                        <span className="treatment-label">Treatment Type:</span>
                                                        <span className="treatment-value">Injection</span>
                                                        <br />

                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faClock} size="1x" /></span>
                                                        <span className="treatment-label">Duration:</span>
                                                        <span className="treatment-value">30 - 45 mins</span>
                                                        <br />

                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faClipboard} fixedWidth="true" size="1x" /></span>
                                                        <span className="treatment-label">Treatment Areas: </span>
                                                        <span className="treatment-value">Frown lines, horizontal forehead lines, crow’s feet</span>
                                                    </p>

                                                    <div class="row">
                                                        <div class="col-lg-6 mb-2 mb-lg-0">
                                                            <a href="/treatments/anti-wrinkle" class="btn btn-green-inverse w-100">More Details</a>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <a href={constants.booking_url} class="btn btn-green w-100">Book Now &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* START OF CARD */}

                            <div class="col-md-6 col-xl-12">
                                <div class={["card", styles.treatmentCard].join(" ")}>
                                    <div class="row g-0">
                                        <div class="col-xl-6">
                                            <img src={DermalFillerCardHeader} class="card-img" alt="Dermal Filler" />
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="card-body pt-lg-0">
                                                <div class="d-flex flex-row justify-content-between card-title ">
                                                    <h5 id="dermal-fillers" class="medi-title">Dermal Fillers</h5>
                                                    <p className={styles.price}>From &pound;220</p>
                                                </div>
                                                <div class="card-text">
                                                    <p>
                                                        Dermal fillers provide a smoothing, long-lasting correction of moderate to severe facial wrinkles and folds in both men and women.
                                                    </p>
                                                    <p>
                                                        They instantly soften tell-tale ageing and enhance your natural beauty by restoring volume, improving elasticity and smooth lines and imperfections within your facial complexion.
                                                    </p>
                                                    <p>
                                                        Dermal fillers that contain hyaluronic acid (naturally produced by your body, with production slowing down as you age) are a popular choice to help with these changes because they can help improve and slow down the signs of ageing and rehydrate the skin tissue. Treatment is quick, convenient, minimally invasive and results can be seen straight away without any recovery period.
                                                    </p>
                                                    <p>
                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faSyringe} size="1x" /></span>
                                                        <span className="treatment-label">Treatment Type:</span>
                                                        <span className="treatment-value">Injection</span>
                                                        <br />

                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faClock} size="1x" /></span>
                                                        <span className="treatment-label">Duration:</span>
                                                        <span className="treatment-value">45 - 90 mins</span>
                                                        <br />

                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faClipboard} fixedWidth="true" size="1x" /></span>
                                                        <span className="treatment-label">Treatment Areas: </span>
                                                        <span className="treatment-value">Nose-to-mouth (nasolabial folds), smile Lines &amp; mouth-to-chin lines (marionette lines)</span>
                                                    </p>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-2 mb-md-0">
                                                            <a href="/treatments/dermal-fillers" class="btn btn-green-inverse w-100">More Details</a>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <a href={constants.booking_url} class="btn btn-green w-100">Book Now &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* START OF CARD */}

                            <div class="col-md-6 col-xl-12">
                                <div class={["card", styles.treatmentCard].join(" ")}>
                                    <div class="row g-0">
                                        <div class="col-xl-6">
                                            <img src={FacialCardHeader} class="card-img" alt="Lip Filler" />
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="card-body pt-lg-0">
                                                <div class="d-flex flex-row justify-content-between card-title ">
                                                    <h5 id="lip-fillers" class="medi-title">Facials</h5>
                                                    <p className={styles.price}>From &pound;30</p>
                                                </div>
                                                <div class="card-text">
                                                    <p>
                                                        A skin treatment that cleanses pores, exfoliates away dead skin cells, and treats skin concerns with a custom mask. This multi-step process rejuvenates and nourishes the face, making skin appear healthier and younger.
                                                    </p>
                                                    <p>
                                                        Facials can have many effects on the skin, including; deep cleansing, tightening, stress reduction and improved appearance of aging.
                                                    </p>
                                                    <p>
                                                        The ideal treatment for a pamper and relax, whilst providing care to your skin.
                                                    </p>
                                                    <p>
                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faSyringe} fixedWidth="true" size="1x" /></span>
                                                        <span className="treatment-label">Treatment Type:</span>
                                                        <span className="treatment-value"> Cleanse/exfoliate/treatment tailored to your needs</span>
                                                        <br />

                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faClock} fixedWidth="true" size="1x" /></span>
                                                        <span className="treatment-label">Duration:</span>
                                                        <span className="treatment-value">30 - 60 mins</span>
                                                        <br />

                                                        <span className="treatment-icon"><FontAwesomeIcon icon={faClipboard} fixedWidth="true" size="1x" /></span>
                                                        <span className="treatment-label">Skin Type: </span>
                                                        <span className="treatment-value">All skin types, can specifically target: Acne prone, dry, dull, tired, aging, oily</span>
                                                    </p>

                                                    <div class="row">
                                                        <div class="col-md-6 mb-2 mb-md-0">
                                                            <a href="/treatments/facials" class="btn btn-green-inverse w-100">More Details</a>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <a href={constants.booking_url} class="btn btn-green w-100">Book Now &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </Layout>
        )
    }
}
import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

import * as constants from '../constants'

import TreatmentInclude from "../components/includes/treatmet-include"
import TestimonialInclude from "../components/includes/testimonial-include"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <title>ACA REJUVENATE CLINIC - Aesthetic Treatments in North Yorkshire</title>
      </Helmet>
      <div class="row-contrast-one">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 p-0">
              <div class="background-face1"></div>
            </div>
            <div class="col-lg-6 call-to-action-area">
              <div class="call-to-action-title">
                Aesthetic Treatments in North Yorkshire
              </div>
              <div class="call-to-action-content">
                <br />
                Welcome to ACA Rejuvenate Clinic, based just outside Selby Centre, in a new and exciting purpose made Clinic. We are here and ready to fulfil all of your facial aesthetic needs, putting passion, care, safety and you at the heart of all we do.
                <br /><br />
                Providing you with the peace of mind that all of our procedures are carried out by highly trained staff. This includes Nursing &amp; Midwifery Council (NMC) Qualified nurses to perform all injectable procedures and Dermal Therapists trained and committed to providing you with the highest level of treatment and skincare.
              </div>
              <div>
                <a className="btn btn-green-inverse call-to-action-button" size="lg" href={constants.booking_url}  target="_blank" rel="noopener noreferrer">
                  Book Now
                </a>
                <a className="btn call-to-action-button btn-green" size="lg" href="/treatments">
                  Aesthetic Procedures
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container">
          <div class="row medi-row">
            <div class="col-lg-6">
              <div class="medi-title">Trusted &amp; Experienced</div>
              <div class="medi-strap">Expert medical professionals providing the best possible, highest quality care</div>
              <div class="medi-content">
                <p>
                  ACA Rejuvenate Clinic is here to bring innovative techniques and new treatments to help you regain and retain natural-looking youth and beauty.
                  With your satisfaction and safety at the forefront of all that we do, you can be assured of the highest quality results achieved in the best way for you.
                </p>
                <p>

                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="medi-content">
                <div class="medi-title d-none d-lg-block">&nbsp;</div>
                <p>
                  All of our injectable procedures are carried out by experienced qualified Nurses who have in-depth knowledge and training within Aesthetics. Our skilled team also includes Facial Therapists who specialise in skincare and anti-ageing.

                </p>
                <p>
                  Through our adaptable consultation approach, we are enabled to design tailor-made treatment plans to create completely natural and understated results, using the leading products within the aesthetic industry; inclusive of dermal fillers and muscle relaxants. Maintaining our focus of enhancing and nurturing the health of your skin in everything we do.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TreatmentInclude />
      <TestimonialInclude />
    </Layout>
  )
}
import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

import TestimonialInclude from "../components/includes/testimonial-include"

import * as styles from "./price-list.module.css"
import DotsElement from "../components/theme/dots"
import { Accordion } from "react-bootstrap"

export default class PriceListPage extends React.Component {
    render() {
        return (
            <Layout id="price-list-page">
                <Helmet>
                    <title>Price List - ACA Rejuvenate Clinic</title>
                </Helmet>


                <div class="bg-green-2 pt-md-5 pb-3 pb-md-5">
                    <div class="container-md">
                        <div class="row">
                            <div class="header-photo col-lg-4">
                            </div>
                            <div class="col-xl-8 about-us-area">
                                <div class="d-xl-none mb-4 mt-5"><DotsElement height="1" color="green" /></div>
                                <div class="mb-4 mt-5 mt-xl-1 d-none d-xl-block"><DotsElement height="2" width="2" color="green" /></div>

                                <div class="medi-title text-white mb-1 d-none d-md-block">PRICE LIST</div>
                                <div class="medi-strap mb-2 d-none d-md-block">ACA Rejuvenate Clinic</div>

                                <div class="medi-strap mb-2 d-block d-md-none">Price List</div>

                                <p>
                                    Here at ACA Rejuvenate Clinic we aim to always be contactable for your every query.
                                </p>
                                <p>
                                    It is essential for you to book a consultation before both anti-wrinkle and dermal filler procedures, as it is in yours and ours best interest to give you confidence in your treatment.
                                </p>
                                <p>
                                    Although a Consultation is required for all needling proceedures it is still helpful to get in touch pre-consultation to discuss your desired outcome and make sure the procedure you want is right for you before we proceed any further.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="container-fluid container-md pt-3 pt-xl-5">

                        <div class="row medi-row pt-0 pt-sm-5">

                            <div class="col-xl-12">
                                <div class="medi-title">Botulinum Toxin - Anti-wrinkle ("Botox")</div>
                                <div class="medi-content">
                                    <p>
                                        It is important to be aware that although anti-wrinkle treatment is extremely effective at reducing and minimising fine lines and wrinkles, it is not a permanent treatment and it is most effective for 12 weeks post procedure.
                                    </p>
                                    <p>
                                        It is an essential requirement to see our Prescriber before your procedure who can generate your prescription for one of our highly trained nurses to administer for your procedure.
                                    </p>
                                    <p>
                                        For any questions please <a href="/contact">contact us here at ACA Rejuvenate Clinic</a>.
                                    </p>


                                    <div class="offset-xl-1 col-xl-10 mt-xl-5">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    Consultation
                                                    <span className={["badge bg-light text-dark", styles.priceBadge].join(" ")}>
                                                        &pound;15
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        You will be provided with a full face-to-face consultation with one of our Nurses who are experts within the field of anti-aging and will help guide you in what treatment is best for you.
                                                    </p>
                                                    <p>
                                                        The cost of the consultation is redeemable against the full price of the procedure.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    Prescriber Appointment
                                                    <span className={["badge bg-light text-dark", styles.priceBadge].join(" ")}>
                                                        &pound; 35
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        As Botulinum toxin (“Botox”) is a prescription only medication it is a legal requirement for you to meet our prescriber face-to-face to provide us with a prescription to order your treatment from the pharmacy.
                                                    </p>
                                                    <p>
                                                        Payment required before appointment and is non-refundable
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    Treatment
                                                    <span className={["badge bg-light text-dark", styles.priceBadge].join(" ")}>
                                                        from &pound;135
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        Payment required before appointment and is non-refundable:
                                                        <ul>
                                                            <li>1 area <span class="badge bg-danger text-decoration-line-through">&pound;150</span> 10% off  <span class="badge bg-secondary">&pound;135</span></li>
                                                            <li>2 areas: <span class="badge bg-danger text-decoration-line-through">&pound;185</span> 15% off  <span class="badge bg-secondary">&pound;155</span></li>
                                                            <li>3 areas: <span class="badge bg-danger text-decoration-line-through">&pound;220</span> 15% off  <span class="badge bg-secondary">&pound;185</span></li>
                                                        </ul>
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    Review Appointment
                                                    <span className={["badge bg-light text-dark", styles.priceBadge].join(" ")}>
                                                        &pound;30
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        A review appointment doesn’t always feel necessary but we offer it to you to give you peace of mind that if aren’t 100% happy we can adjust your treatment accordingly
                                                    </p>
                                                    <p>
                                                        Follow up appointments must take place between day 14 and 21 post procedure.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row medi-row pt-0 pt-sm-5">

                            <div class="col-xl-12">
                                <div class="medi-title">Dermal Fillers</div>
                                <div class="medi-content">
                                    <p>
                                        Dermal Filler can be used to soften marionette lines, nasolabial folds and smile lines, price quoted is based on 1ml of the exceptional JUVÉDERM&reg; filler we commit to use here at ACA Rejuvenate Clinic as the number one dermal filler product, for best performance and safety in the globally.
                                    </p>
                                    <p>
                                        In-depth treatment plan and quotes are provided in your initial consultation.
                                    </p>


                                    <div class="offset-xl-1 col-xl-10 mt-xl-5">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    Consultation
                                                    <span className={["badge bg-light text-dark", styles.priceBadge].join(" ")}>
                                                        &pound;15
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        You will be provided with a full face-to-face consultation with one of our Nurses who are experts within the field of anti-aging and will help guide you in what treatment is best for you.
                                                    </p>
                                                    <p>
                                                        The cost of the consultation is redeemable against the full price of the procedure.
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    Treatment
                                                    <span className={["badge bg-light text-dark", styles.priceBadge].join(" ")}>
                                                        from &pound;220
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        <span class="medi-title">Dermal filler JUVÉDERM&reg; Ultra 3</span>
                                                        <ul>
                                                            <li>1ml from <span class="badge bg-danger text-decoration-line-through">&pound;260</span> 15% off  <span class="badge bg-secondary">&pound;220</span></li>
                                                            <li>Additional per ml: <span class="badge bg-danger text-decoration-line-through">&pound;240</span> 20% off  <span class="badge bg-secondary">&pound;190</span></li>
                                                        </ul>

                                                        <span class="medi-title">Dermal filler JUVÉDERM&reg; Volift</span>
                                                        <ul>
                                                            <li>1 ml from <span class="badge bg-danger text-decoration-line-through">&pound;325</span> 15% off  <span class="badge bg-secondary">&pound;275</span></li>
                                                            <li>Additional per ml: <span class="badge bg-danger text-decoration-line-through">&pound;310</span> 20% off  <span class="badge bg-secondary">&pound;245</span></li>
                                                        </ul>
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row medi-row pt-0 pt-sm-5">

                            <div class="col-xl-12">
                                <div class="medi-title">Facials</div>
                                <div class="medi-content">

                                    <p>
                                        Facial provided by our expert Facial Therapist using the renowned facial skincare range Skintruth&reg; range
                                    </p>


                                    <div class="offset-xl-1 col-xl-10 mt-xl-5">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    Treatment
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>
                                                        <span class="medi-title">Express facial <span class="badge bg-secondary">&pound;30</span></span>
                                                        <ul>
                                                            <li>Cleanse, tone, exfoliate, moisturise:</li>
                                                        </ul>

                                                        <span class="medi-title">Hydrating <span class="badge bg-secondary">&pound;40</span></span>
                                                        <ul>
                                                            <li>Cleanse, tone, exfoliate, face mask, intensive moisturise </li>
                                                        </ul>

                                                        <span class="medi-title">Brightening <span class="badge bg-secondary">&pound;40</span></span>
                                                        <ul>
                                                            <li>Cleanse, tone, exfoliate, face mask, moisturise</li>
                                                        </ul>
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                <TestimonialInclude />
            </Layout >
        )
    }
}
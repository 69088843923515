import React from "react"
import ReactDOM from "react-dom";

import * as style from "./dots.module.css"

const CROSS_WIDTH = 48;

export default class DotsElement extends React.Component {
    parent = null;

    constructor(props) {
        super(props);
        this.heightSetting = props.height;
        this.widthSetting = props.width;
        this.color = props.color;
        this.dots = React.createRef();
    }

    updateDimensions = () => {

        if (this.heightSetting != null) {
            console.log("setting height")
            this.dots.current.style.height = (CROSS_WIDTH * this.heightSetting) + "px"
        }

        if (this.widthSetting != null) {
            this.dots.current.style.width = (CROSS_WIDTH * this.widthSetting) + "px"
        } else {
            var numberOfCrosses = parseInt(this.parent.offsetWidth / CROSS_WIDTH) - 1;
            numberOfCrosses = parseInt(numberOfCrosses / 2) + 1;
            this.dots.current.style.width = (numberOfCrosses * CROSS_WIDTH) + "px"
        }
    };
    addClasses = () => {
        var classes = [style.dots]

        // Automatic width? Need to add auto margins
        if (! this.widthSetting) {
            classes.push("ms-auto")
            classes.push("me-auto")
        }

        if (this.color === "green") {
            classes.push(style.dotsGreen)
        } else if (this.color === "white") {
            classes.push(style.dotsWhite)
        } else if (this.color === "light-green") {
            classes.push(style.dotsLightGreen)
        } else {
            throw new Error("Unsupported color for DotsElement: " + this.color)
        }

        this.dots.current.className = classes.join(" ")
  }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.parent = ReactDOM.findDOMNode(this.dots.current).parentNode;
        this.addClasses()
        this.updateDimensions()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    render() {
        return (
            <div ref={this.dots} ></div>
        )
    }
}
import React from "react"

import WrinkleCardHeader from "../../../static/anti-wrinkle.jpg"
import DermalFillerCardHeader from "../../../static/dermal-fillers.jpg"
import FacialCardHeader from "../../../static/facials.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default function TreatmentInclude() {
    return (
        <div class="bg-green-2 bg-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="text-center">
                            <div class="medi-strap mb-1">Our most popular treatments</div>
                            <div class="medi-content mb-3">
                                Specialist treatments performed by experienced, qualified specialists
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <div class="card shadow h-100">
                            <img src={WrinkleCardHeader} class="card-img-top" alt="..." />
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Anti-wrinkle Treatment</h5>
                                <p class="card-text">Using specialist techniques, performed solely by highly experienced qualified nurses, these injectable procedures can reduce the appearance of both fine lines and wrinkles, creating a more youthful, vibrant and natural look.</p>
                                <div class="text-center pt-3 pb-2 mt-auto">
                                    <a href="/treatments#anti-wrinkle" class="btn btn-green-inverse">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <div class="card shadow h-100">
                            <img src={DermalFillerCardHeader} class="card-img-top" alt="..." />
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Dermal Fillers</h5>
                                <p class="card-text">A minimally invasive procedure, fulfilled by professionals with a high level of expertise. A soft tissue booster to provide facial volume and enhance facial features, restoring a smoother appearance and minimize the presence of fine lines and wrinkles.</p>
                                <div class="text-center pt-3 pb-2 mt-auto">
                                    <a href="/treatments#dermal-fillers" class="btn btn-green-inverse">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <div class="card shadow h-100">
                            <img src={FacialCardHeader} class="card-img-top" alt="..." />
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Facials</h5>
                                <p class="card-text">An experience performed by formally trained facial therapists, providing a bespoke treatment tailored to your specific skin type and desired outcome. Facials have the ability to combine different high-quality products and skilled techniques to create a more radiant, fresh-faced and rejuvenated skin.</p>
                                <div class="text-center pt-3 pb-2 mt-auto">
                                    <a href="/treatments#facials" class="btn btn-green-inverse">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

import * as styles from "./about.module.css"

import TestimonialInclude from "../components/includes/testimonial-include"
import DotsElement from "../components/theme/dots";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"

export default class AboutPage extends React.Component {
  render() {
    return (
      <Layout id="about-page">
        <Helmet>
          <title>About ACA Rejuvenate Clinic</title>
        </Helmet>
        <div class="bg-green-2 pt-md-5 pb-md-5">
          <div class="container-md">
            <div class="row">
              <div class="header-photo col-lg-4">
              </div>
              <div class="col-lg-8 about-us-area">
                <div class="d-lg-none mb-4 mt-5"><DotsElement height="1" color="green" /></div>
                <div class="medi-title text-white mb-1 d-none d-md-block">ABOUT US</div>
                <div class="medi-strap mb-2">ACA Rejuvenate Clinic</div>
                <p>
                  ACA Rejuvenate Clinic has been founded with the purpose of providing you with the with the best possible skin treatments whilst being able to offer you reassurance and peace of mind that you are in the safest hands possible.
                </p>
                <p>
                  Giving you confidence that we put you and your safety at the centre of all we do.
                </p>
                <p>
                  All of our staff are highly trained within their field of expertise and all needling procedures carried out by Qualified Nurses, additionally trained within the field of Facial Aesthetics.
                </p>
                <p>
                  Our aim is to give you the knowledge of your options and suitability for you, we will never provide something that is not right for you. We look to address all of your concerns you expressed within your consultation and support you to choose the treatment which would benefit you most.
                </p>
                <p>
                  At ACA Rejuvenate Clinic our ethos is to help you enhance and regain your own beauty and confidence within your own skin. We will not perform any procedure which would result in an unnatural finish.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="container pt-xl-5">
            <div class="row medi-row pt-0 pt-sm-5">

              {/* About Image 1 - For SMALL screens */}
              <div className={[styles.aboutImage, styles.aboutImage1, "col-lg-6", "mb-4", "d-block", "d-lg-none"].join(' ')}></div>

              <div class="col-lg-6">
                <div class="medi-title">Why choose us?</div>
                <div class="medi-content">
                  <ul>
                    <li>Experienced team of friendly aesthetic nurses and facial therapists, who will assess, plan and carry out your treatments</li>
                    <li>We offer all the latest non-surgical cosmetic procedures and skin treatments</li>
                    <li>We only use safe CE marked and high-quality products</li>
                    <li>Non-pushy and honest service</li>
                    <li>Ongoing after care</li>
                    <li>Follow up appointments if needed</li>
                    <li>Qualified Nurses within the team are all members of the ACE – Aesthetic Complications Expert Group – ensuring our clients safety is maintained at all times.</li>
                  </ul>
                </div>
              </div>

              {/* About Image 1 - For LARGE screens */}
              <div className={[styles.aboutImage, styles.aboutImage1, "col-lg-6", "mb-0", "d-none", "d-lg-block"].join(' ')}></div>


            </div>
          </div>

          <div class="bg-green-2 bg-padding mt-5">
            <div class="container-fluid container-sm">
              <div class="row">
                <div class="col-md-12 mb-2">


                  <div class="col-md-12 mb-2 mb-5">
                    <div class="text-center">
                      <div class="medi-strap mb-1">Meet our team</div>
                      <div class="medi-content mb-4">
                        The ACA Rejuvenate Clinic specialists have broad experience <br /> across the healthcare and beauty sector
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div class="row">

                <div className={[styles.aboutImage, styles.amyImage, "col-lg-3", "mb-4"].join(' ')}></div>

                <div class="col-lg-9 ps-lg-5">
                  <div class="medi-content">
                    <div class="medi-strap mb-2">Amy Astin</div>
                    <div class="medi-title text-white">Clinical Director &amp; Aesthetic Nurse Practitioner</div>
                    <p>
                      <sup class="quote-mark"><FontAwesomeIcon icon={faQuoteLeft} />&nbsp;</sup>&nbsp;
                      I began my career in Nursing within a Critical Care setting, becoming an expert in problem solving, emergency medicine and providing life-saving treatment. Although some of the skills I gained aren’t required in Aesthetic practice (thank goodness!), it has provided me with excellent assessment skills, safety awareness and attention to detail. I like to think of myself as a perfectionist and determined to achieve the best outcome for people in my care.
                    </p>
                    <p>
                      The Aesthetic Industry has long been a passion of mine, giving me the ability to continue to provide a high level of care to my clients, assessing, developing treatment plans and assisting to create a natural, enhanced beauty for each of them.
                    </p>
                    <p>
                      Because of my background safety is at the forefront of everything I do and, to me it is essential to provide confidence in the safety of every procedure I undertake, using my medical knowledge to provide this in the safest possible way.
                    </p>
                    <p>
                      I would like to welcome you to ACA Rejuvenate Clinic and I look forward to getting to know you on your Aesthetic journey
                      &nbsp;<sup class="quote-mark"><FontAwesomeIcon icon={faQuoteRight} />&nbsp;</sup>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="col-lg-12">
                  <DotsElement height="1" color="green" />
                </div>
              </div>
              <div class="row">
                <div className={[styles.aboutImage, styles.rebeccaImage, "col-lg-3", "mb-4"].join(' ')}></div>

                <div class="col-lg-9 ps-lg-5">
                  <div class="medi-strap mb-2">Rebecca Carney</div>
                  <div class="medi-title text-white">Facial Therapist</div>

                  <div class="medi-content">
                    <p>
                      <sup class="quote-mark"><FontAwesomeIcon icon={faQuoteLeft} />&nbsp;</sup>&nbsp;
                      Skincare has always been a great passion of mine, through excellent training and experience I am able to provide you with a treatment that will bring you a refreshed, rejuvenated more youthful version of you.
                    </p>
                    <p>
                      Providing a pre-treatment assessment, giving you time to address your concerns, together we can decide a treatment plan that’s right for you. Your satisfaction and confidence in me to provide this is what matters the most to me.
                    </p>
                    <p>
                      To me a facial is not just a treatment, it is an experience. With great attention paid to ambience, comfort and relaxation, I am able to give you a tailor-made experience, whether you’re looking for stress-relief, well-being or just a treat from you to you!

                    </p>
                    <p>
                      Here at ACA Rejuvenate Clinic we ensure you are given the time and expert support to achieve the best results for you.
                      &nbsp;<sup class="quote-mark"><FontAwesomeIcon icon={faQuoteRight} />&nbsp;</sup>
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TestimonialInclude />
      </Layout>
    )
  }
}
import React from "react"

import Testimonial1 from "../../../static/testimonial-1.jpg"
import Testimonial2 from "../../../static/testimonial-2.jpg"
import Testimonial3 from "../../../static/testimonial-3.jpg"
import Testimonial4 from "../../../static/testimonial-4.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"

export default function TestimonialInclude() {
    return (


        <div class="bg-white pb-0 bg-padding">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="text-center">
                            <div class="medi-strap mb-1">What our clients say</div>
                            <div class="medi-content mb-4">
                                At ACA Rejuvenate Clinic offer consistent, professional aesthetic and beauty treatments. <br />
                                We are proud of the results that we have achieve with our clients and would like to share their feedback
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-6">
                        <div class="card mb-3 testimonial-card">
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src={Testimonial1} class="card-img" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <p class="card-text">
                                            <sup class="quote-mark"><FontAwesomeIcon icon={faQuoteLeft} />&nbsp;</sup>
                                            Very relaxing environment for a facial, felt completely relaxed and rejuvenated after. My skin is glowing, got exactly what I came for!
                                            <sup class="quote-mark">&nbsp;<FontAwesomeIcon icon={faQuoteRight} /></sup>
                                        </p>
                                        <p class="card-text"><small class="text-muted">Lauren, 35</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card mb-3 testimonial-card">
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src={Testimonial2} class="card-img" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <p class="card-text">
                                            <sup class="quote-mark"><FontAwesomeIcon icon={faQuoteLeft} />&nbsp;</sup>
                                            Fab experience, love the results! Amy was lovely through every step of the process, will definitely be recommending to friends and family.
                                            <sup class="quote-mark">&nbsp;<FontAwesomeIcon icon={faQuoteRight} /></sup>
                                        </p>
                                        <p class="card-text"><small class="text-muted">Katie, 30</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col-lg-6">
                        <div class="card mb-3 testimonial-card">
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src={Testimonial3} class="card-img" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <p class="card-text">
                                            <sup class="quote-mark"><FontAwesomeIcon icon={faQuoteLeft} />&nbsp;</sup>
                                            My antiwrinkle treatment has boosted my confidence at work.
                                            I have a client-facing role and first impressions matter!
                                            <sup class="quote-mark">&nbsp;<FontAwesomeIcon icon={faQuoteRight} /></sup>
                                        </p>
                                        <p class="card-text"><small class="text-muted">Jamie, 34</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card mb-3 testimonial-card">
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src={Testimonial4} class="card-img" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <p class="card-text">
                                            <sup class="quote-mark"><FontAwesomeIcon icon={faQuoteLeft} />&nbsp;</sup>
                                            Amy made me feel at ease as I expressed I had a fear of needles, she managed to keep me calm and distract me from the procedure. 
                                            The results were amazing and lasted longer than I expected.
                                            <sup class="quote-mark">&nbsp;<FontAwesomeIcon icon={faQuoteRight} /></sup>
                                        </p>
                                        <p class="card-text"><small class="text-muted">Tina, 63</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
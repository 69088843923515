import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

export default class AboutPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>

        <div class="">
          <div class="container pt-xl-5">
            <div class="row medi-row pt-0 pt-sm-5">

              <div class="col-lg-12">
                <div class="medi-title">Page Not Found</div>
                <div class="medi-content">
                  <p>
                    The page you are looking for could not be found.
                  </p>
                </div>
              </div>


            </div>
          </div>
        </div>

      </Layout>
    )
  }
}
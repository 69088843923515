import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBriefcaseMedical, faHourglassHalf, faSyringe, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faCalendarAlt, faClipboard, faClock } from '@fortawesome/free-regular-svg-icons'
import DotsElement from "../../components/theme/dots"

import * as styles from "./facials.module.css"
import * as constants from '../../constants'

export default class FacialTreatment extends React.Component {
    render() {
        return (
            <Layout id="facials-page">
                <Helmet>
                    <title>Facial Treatment - ACA Rejuvenate Clinic</title>
                </Helmet>

                <div class="bg-green-2 pt-md-5 pb-3 pb-md-5">
                    <div class="container-md">
                        <div class="row">
                            <div class="header-photo col-lg-4"></div>
                            <div class="col-lg-8 about-us-area pt-4 pb-2">
                                <div class="medi-title text-white mb-1">TREATMENTS</div>
                                <div class="medi-strap mb-2">Facial Treatment</div>
                                <p>
                                    No matter what your skin wants or needs our Specialist Facial Therapists are here to provide you
                                    with a bespoke treatment plan, perfect for both your skin and you.
                                </p>
                                <p>
                                    Regardless of your skin concerns; acne, hydration issues, oily, aging, tired, dull we are here to create
                                    a tailor-made skin care plan, using fantastic tried and tested, market leading skin care products,
                                    individualised for you.
                                </p>
                                <p>
                                    All facials come with a complementary skin care consultation so we can help you choose what the best
                                    approach for you is.
                                </p>
                                <a href={constants.booking_url} class="btn btn-green-inverse booking-button">Book Now &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="">
                    <div class="container">
                        <div class="row medi-row pt-3 pt-sm-5">

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Treatment  key facts</div>
                                <div class="medi-content mb-3 mb-lg-5">What to expect from a facial treatment</div>
                            </div>
                            <div class="col-lg-4 col-md-6">

                                <span className="treatment-icon"><FontAwesomeIcon icon={faSyringe} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Treatment Type</span><br />
                                <span className="treatment-value treatment-value-nl">Cleanse / exfoliate / moisturise<br /> Tailored to your needs</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faBriefcaseMedical} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Skin Types</span><br />
                                <span className="treatment-value treatment-value-nl">All skin types, can specifically target:<br />Acne prone, dry, dull, tired, aging, oily</span>
                            </div>

                            <div class="col-lg-4 col-md-6">

                                <span className="treatment-icon"><FontAwesomeIcon icon={faClipboard} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Treatment Areas</span><br />
                                <span className="treatment-value treatment-value-nl">Face, cheeks, chin, T-zone, pores</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faClock} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Duration</span><br />
                                <span className="treatment-value treatment-value-nl">30 - 60 mins</span>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <span className="treatment-icon"><FontAwesomeIcon icon={faCalendarAlt} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Frequency</span><br />
                                <span className="treatment-value treatment-value-nl">As often as you like</span>
                                <br />

                                <span className="treatment-icon"><FontAwesomeIcon icon={faHourglassHalf} fixedWidth="true" size="1x" /></span>
                                <span className="treatment-label">Recovery Period</span><br />
                                <span className="treatment-value treatment-value-nl">None</span>
                            </div>


                            <div className="col-lg-12">
                                <DotsElement height="1" color="green" />
                            </div>

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Frequently asked questions</div>
                                <div class="medi-content mb-3 mb-lg-5">All your questions answered by our medical staff</div>
                            </div>
                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">
                                        What can a facial provide for you?
                                    </p>
                                    <p>
                                        There are many, wide-ranging benefits of facial treatments:
                                        <ul>
                                            <li>Stress reduction and emotional balance</li>
                                            <li>Proper deep cleansing of the skin</li>
                                            <li>Reduced ageing</li>
                                            <li>Improved blood circulation</li>
                                            <li>Rejuvenation and detoxification of the skin</li>
                                            <li>Treatment of acne and eradication of pimples and blackheads</li>
                                            <li>Unblocking pores and skin exfoliation</li>
                                            <li>Tightening skin</li>
                                            <li>Reducing eye bags and pigmentation</li>
                                        </ul>
                                    </p>

                                    <p class="medi-title">Can I have other treatments at the same time?</p>
                                    <p>
                                        In view of the other procedures we offer, please be advised that a facial must not be booked within 14 days of receiving an anti-wrinkle or dermal filler procedure.
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="medi-content">
                                    <p class="medi-title">What can I expect?</p>
                                    <p>
                                        Having a facial is a unique experience bespoke to you and your desired outcome.
                                    </p>
                                    <p>
                                        One of our expertly trained Facial Therapists will begin by providing a consultation of your skin and discussing with you what you would like to achieve from your facial. From this they will tailor a plan for your treatment, putting your skin issues and experience at the centre of this.
                                    </p>
                                    <p>
                                        Using tried and tested excellent quality products, to cleanse, exfoliate and treat your skin concerns, you can be guaranteed a relaxing, soothing and corrective facial tailored to your skin care needs.
                                    </p>
                                    <p class="medi-title">Is there After Care?</p>
                                    <p>
                                        You will be provided with the information and shown the products used by you Facial Therapist and advised how to maintain your skin to achieve the best out of your facial.
                                    </p>
                                    <p>
                                        With the right knowledge and tools you can maintain your beautiful skin between facials and receive the best outcome every time you treat yourself to another facial here at ACA Rejuvenate Clinic.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>


                <div class="bg-green-2 pt-5">
                    <div class="container mt-2">

                        <div class="row">

                            <div class="col-lg-12 text-center">
                                <div class="medi-strap mb-1">Other popular treatments</div>
                                <div class="medi-content mb-3 mb-lg-5">Other treatments offered by ACA Rejuvenate Clinic</div>
                            </div>



                            <div class="col-lg-6 mb-3 mb-lg-5">
                                <div class={["card mb-2 h-100"].join(" ")}>
                                    <div class="row g-0 h-100">
                                        <div className={["col-md-6 mb-2 mb-md-0", styles.antiwrinkleCardPhoto].join(" ")}>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="card-body d-flex flex-column h-100">
                                                <h5 class="card-title">Anti-wrinkle Treatment</h5>
                                                <p class="card-text">Using specialist techniques, performed solely by highly experienced qualified nurses, these injectable procedures can reduce the appearance of both fine lines and wrinkles, creating a more youthful, vibrant and natural look.</p>
                                                <a href="/treatments/anti-wrinkle" class="btn btn-green-inverse mt-auto">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-3 mb-lg-5">
                                <div class={["card mb-2 h-100"].join(" ")}>
                                    <div class="row g-0 h-100">
                                        <div className={["col-md-6 mb-2 mb-md-0", styles.dermalFillersCardPhoto].join(" ")}>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="card-body d-flex flex-column h-100">
                                                <h5 class="card-title">Dermal Fillers</h5>
                                                <p class="card-text">A minimally invasive procedure, fulfilled by professionals with a high level of expertise. A soft tissue booster to provide facial volume and enhance facial features, restoring a smoother appearance and minimize the presence of fine lines and wrinkles.</p>
                                                <a href="/treatments/dermal-fillers" class="btn btn-green-inverse mt-auto">Find Out More &nbsp; <FontAwesomeIcon icon={faChevronRight} /></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </Layout>
        )
    }
}